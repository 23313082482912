// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-art-archive-js": () => import("./../../../src/templates/artArchive.js" /* webpackChunkName: "component---src-templates-art-archive-js" */),
  "component---src-templates-art-post-js": () => import("./../../../src/templates/artPost.js" /* webpackChunkName: "component---src-templates-art-post-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-types-post-js": () => import("./../../../src/templates/types/post.js" /* webpackChunkName: "component---src-templates-types-post-js" */)
}

