import "./src/assets/styles/en-fonts.css";
import "./src/assets/styles/ja-fonts.css";
import "./src/assets/styles/main.scss";
import "./src/assets/styles/typography.scss";

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // const currentPosition = getSavedScrollPosition(location);

  // const targetPosition = currentPosition || [0, 0];

  // if (!location.hash) {
  //   setTimeout(() => {
  //     window.scrollTo(...targetPosition);
  //   }, 50);
  // }

  // return false;
};
